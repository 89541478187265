import React, { useState } from "react"
import Layout from "../components/layout"
import InnerPageLayout from "../components/inner-page-layout"

export default function Contact() {

    const [showSubmitMessage, setShowSubmitMessage] = React.useState(false)

    const submitForm = e => {
   
      e.preventDefault();
      console.log('submitting form');

      const payload = {
          config: 'LCJ',
          email: 'leedscityjnrs@gmail.com',
          subject: 'New form submission from website',
          body: `Name: ${e.target.name.value}\r\nTelephone: ${e.target.telephone.value}\r\nEmail: ${e.target.email.value}\r\nMessage: ${e.target.message.value}\r\n` 
      };

      fetch('https://mail.mistryhq.com/api/mail/send', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => {
        console.log('form submitted successfully');
        res.json();
        setShowSubmitMessage(true);
      });
  }

  return ( 
    <Layout>
        <section className="container">
        <h1>Contact Us </h1>
        <div className="row mb-2">
            <div className="col-md-8 mb-4">
                <img src="stock-contactus-wide.jpg" className="img-fluid mb-4 inner-page-hero" />
                <p className="lead">Get in touch!</p>
                <p>Complete the form below and somebody will be in touch with you shortly. If you are a parent/guardian interested in your child joining to train/play at Leeds City Juniors, you can also email the manager of the relevant team (contact details are on the team pages).</p>
                { showSubmitMessage ? (
                    <div>
                        <h4>Form submitted successfully</h4>
                        <p>Thank you for getting in touch. One of our colleagues will get back in touch with you soon!</p>
                    </div>
                ) : (
                    <form onSubmit={submitForm}>
                        <div className="mb-3">
                        <label for="name" className="form-label">Name</label>
                        <input type="text" className="form-control" id="name" name="name" placeholder="Firstname Lastname" required />
                        </div>
                        <div className="mb-3">
                        <label for="telephone" className="form-label">Telephone</label>
                        <input type="text" className="form-control" id="telephone" name="telephone" placeholder="0800 800 800" />
                        </div>
                        <div className="mb-3">
                        <label for="email" className="form-label">Email</label>
                        <input type="email" className="form-control" id="email" name="email" placeholder="name@example.com" required />
                        </div>
                        <div className="mb-3">
                        <label for="message" className="form-label">Message</label>
                        <textarea className="form-control" id="message" name="message" rows="3" required></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                )}
            </div>
            <div className="col-md-4">
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div className="col p-4 d-flex flex-column position-static">
                        <h3>Club Address</h3>
                        <address>
                            <strong>Leeds City Juniors FC</strong>
                            <p>Adel War Memorial Association<br />
                            Church Lane, Adel<br />
                            Leeds<br />
                            West Yorkshire<br />
                            LS16 8DE
                            </p>
                        </address>
                    </div>
                </div>
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div className="col p-4 d-flex flex-column position-static">
                        <h3>Email Us</h3>
                        <address>
                            <strong><a href="mailto:leedscityjnrs@gmail.com">leedscityjnrs@gmail.com</a></strong>
                        </address>
                    </div>
                </div>
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div className="col p-4 d-flex flex-column position-static">
                        <h3>AWMA</h3>
                        <p>Leeds City Juniors are based at the Adel War Memorial Association (AWMA).</p>
                        <dl>
                            <dt>Website</dt>
                            <dd><a href="https://adelclub.co.uk/" target="_blank">https://adelclub.co.uk/</a></dd>
                        </dl>                        
                    </div>
                </div>
            </div>
        </div>
      </section>
    </Layout>
  );
}
